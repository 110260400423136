import { SUBSCRIBER_FILTER_SEARCH, SUBSCRIBER_LIST, SUBSCRIBER_FILTER_UPDATE } from "../actionTypes";
const initialState = {
  subscribers: null,
  filterSource: { status: '', searchTerm: '' },
  Loading: true,
  refresh: false
};
function reducer(state = initialState, action) {
  switch (action.type) {
    case "reset":
      return initialState;
    case SUBSCRIBER_FILTER_UPDATE:
      return {
        ...state,
        filterSource: action.payload
      };

    case SUBSCRIBER_FILTER_SEARCH:
      return {
        ...state,
        subscribers: action.payload.data,
        filterSource: action.payload.filterSource,
        Loading: false,
        refresh: false
      };
    default:
      return state;
  }
}

export { reducer as default }