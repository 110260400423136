
const env = {
    API_URL: 'https://localhost:44369',
    CORS_URL: 'http://localhost:3000',
    PREVIEW: 'https://localhost:44319'
}
//Live
if (process.env.NODE_ENV === 'production') {
    env.API_URL = "https://service.cms.thewinchesterhotel.co.uk";
    env.CORS_URL = "https://site-manager.thewinchesterhotel.co.uk";
    env.PREVIEW = 'https://thewinchesterhotel.co.uk'
}
// if (process.env.NODE_ENV === 'production') {
//     env.API_URL = "https://service4.xeniaone.com";
//     env.CORS_URL = "https://site8.xeniaone.com";
//     env.PREVIEW = 'https://site9.xeniaone.com'
// }
// if (process.env.NODE_ENV === 'production') {
//     env.API_URL = "https://notifierdemo.eu.hostack.com";
//     env.CORS_URL = "http://notifier.eu.hostack.com";
//     env.PREVIEW = 'https://epsdemo.eu.hostack.com'
// }
export { env as ServiceUrls };