export const SIDEBAR_MENUS = [
    {
        id: '1',
        text: 'Site Configuration',
        url: '/g/site-config',
        icon: 'settings',
        active: true,
        disabled: false,
        form: 'SiteConfig'
    },
    {
        id: '2',
        text: 'Static File Manager',
        url: '/g/static-file-manage',
        active: false,
        icon: 'cloud_upload',
        disabled: false,
        form: 'StaticFile'
    },
    {
        id: '3',
        text: 'Manage Pages',
        url: '/g/manage-pages',
        icon: 'pages',
        active: false,
        disabled: false,
        form: 'PageManager'
    },
    {
        id: '4',
        text: 'Blog',
        url: '/g/blog-manager',
        icon: 'view_module',
        active: false,
        disabled: false,
        form: 'Blogs'
    },
    {
        id: '5',
        text: 'Offer Management',
        url: '/g/offer-management',
        icon: 'local_offer',
        active: false,
        disabled: false,
        form: 'Offers'
    },
    {
        id: '6',
        text: 'Room Management',
        url: '/g/room-management',
        icon: 'local_offer',
        active: false,
        disabled: true,
        form: 'Room'
    },
    {
        id: '7',
        text: 'Subscription Management',
        url: '/g/sbscriber-management',
        icon: 'local_offer',
        active: false,
        disabled: true,
        form: 'Subscriber'
    },
    {
        id: '8',
        text: 'Spa Medical Questionnaire',
        url: '/g/spa-medical-questionnaire',
        icon: 'local_offer',
        active: false,
        disabled: true,
        form: 'SpaMedical'
    }
];

export const PublicKey = "mV2lm-d#HlfU0d5";