import { SPAMEDICAL_FILTER_SEARCH, SPAMEDICAL_LIST, SPAMEDICAL_FILTER_UPDATE } from "../actionTypes";
const initialState = {
  spaMedList: null,
  filterSource: { status: '', fromDate: '', toDate: '' },
  Loading: true,
  refresh: false
};
function reducer(state = initialState, action) {
  switch (action.type) {
    case "reset":
      return initialState;
    case SPAMEDICAL_FILTER_UPDATE:
      return {
        ...state,
        filterSource: action.payload
      };

    case SPAMEDICAL_FILTER_SEARCH:
      return {
        ...state,
        spaMedList: action.payload.data,
        filterSource: action.payload.filterSource,
        Loading: false,
        refresh: false
      };
    default:
      return state;
  }
}

export { reducer as default }