import { SIDEBAR_MENUS } from '../../StaticSources'
import { ServiceUrls } from '../../components/env'
import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";
import { PublicKey } from "../../StaticSources";
//console.log(process.env);
function getHeader() {
  var _ect = sessionStorage.getItem("_ect");
  var token = null;
  if (_ect) {
    let bytes = AES.decrypt(_ect, PublicKey);
    let tokenString = bytes.toString(CryptoJS.enc.Utf8);
    if (tokenString) {
      token = JSON.parse(tokenString);
    }
  }
  if (token) {
    return {
      'Authorization': 'Bearer ' + token.AccessToken,
      'Access-Control-Allow-Origin': ServiceUrls.CORS_URL
    }
  }
  return { 'Access-Control-Allow-Origin': ServiceUrls.CORS_URL };
}
function menuService(id, callback) {
  return function (dispatch) {
    dispatch(callback(id, SIDEBAR_MENUS))
  }
}
function formService(data, service, callback) {
  return function (dispatch) {
    let formParams = {
      method: 'POST',
      body: data,
      headers: getHeader(),
    };
    return fetch(`${ServiceUrls.API_URL}/api/${service}`, formParams)
      .then(data => data.json(), { cache: "no-cache" })
      .then(data => {
        // console.log(data);
        dispatch(callback("id", data))
      })
      .catch((error) => {
        dispatch(callback("id", [], error))
      });
  }
}
function formServiceReqular(data, service, callback) {
  let formParams = {
    method: 'POST',
    body: data,
    headers: getHeader(),
  };
  return fetch(`${ServiceUrls.API_URL}/api/${service}`, formParams)
    .then(data => data.json(), { cache: "no-cache" })
    .then(data => {
      //console.log(data);
      callback("id", data)
    })
    .catch((error) => {
      callback("id", [], error)
    });
}
function formBodyService(data, service, callback) {
  let formParams = {
    method: 'POST',
    body: data,
    json: true,
    headers: Object.assign({}, getHeader(), { 'content-type': 'application/json' }),
  };
  return fetch(`${ServiceUrls.API_URL}/api/${service}`, formParams)
    .then(data => data.json(), { cache: "no-cache" })
    .then(data => {
      //console.log(data);
      callback("id", data)
    })
    .catch((error) => {
      callback("id", { IsSaved: false }, error)
    });
}
function categoryService(service, callback) {
  return function (dispatch) {
    let formParams = {
      method: 'GET',
      headers: getHeader()
    };
    return fetch(`${ServiceUrls.API_URL}/api/${service}`, formParams)
      .then(data => data.json(), { cache: "no-cache" })
      .then(data => {

        dispatch(callback(data))
      })
      .catch((error) => {
        dispatch(callback([], error))
      });
  }
}
function getService(service, callback) {
  debugger;
  return function (dispatch) {
    let formParams = {
      method: 'GET',
      headers: getHeader()
    };
    return fetch(`${ServiceUrls.API_URL}/api/${service}`, formParams)
      .then(data => {
        return data.json()
      }, { cache: "no-cache" })
      .then(data => {
        dispatch(callback(data))
      })
      .catch((error) => {
        dispatch(callback([], error))
      });
  }
}
function getServiceRegular(service, callback) {
  let formParams = {
    method: 'GET',
    headers: getHeader()
  };
  return fetch(`${ServiceUrls.API_URL}/api/${service}`, formParams)
    .then(data => {
      return data.json()
    }, { cache: "no-cache" })
    .then(data => {
      callback(data)
    })
    .catch((error) => {
      callback(null, error)
    });
}
export { menuService, formService, categoryService, getService, formBodyService, formServiceReqular, getServiceRegular, getHeader }