import { combineReducers } from "redux";
import general from "./general";
import blog from "./blog";
import storage from "./storage";
import pages from "./pages";
import siteconfig from "./siteconfig";
import offer from "./offer";
import room from "./room";
import subscriber from "./subscriber";
import spamedical from "./spamedical";
export default combineReducers({ general, blog, storage, pages, siteconfig, offer, room, subscriber, spamedical });